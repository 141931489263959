import React, {FC, useEffect, useState} from 'react';
import './ModalNotificationAutomationAdd.scss';
import {
    NotificationAutomationCategoriesModel
} from "../../../models/NotificationAutomation/NotificationAutomationCategoriesModel";
import {useNotificationAutomation} from "../../../providers/NotificationAutomation/NotificationAutomationProvider";
import ModalDefault from '../../ModalDefault/ModalDefault';
import {Controller, useForm} from "react-hook-form";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {useAuth} from "../../../providers/AuthProvider";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema} from "./Validate";
import ReactSelect, {MultiValue} from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import {
    NotificationAutomationTypeEnum,
    NotificationAutomationTypeEnumDescription
} from "../../../models/NotificationAutomation/NotificationAutomationTypeEnum";
import {Tab, Tabs} from "react-bootstrap";
import {ChannelsEnum} from "../../../models/Campaigns/ChannelsEnum";
import InputDropzone from "../../InputDropzone/InputDropzone";
import TagifyDefault from "../../TagifyDefault/TagifyDefault";
import PreviewCampaigns from "../../Campaigns/Campaigns/PreviewCampaigns/PreviewCampaigns";
import HtmlEditor from "../../HtmlEditor/HtmlEditor";
import getBase64 from "../../../utils/ConvertFiletoBase64";
import {contractTypes} from "../../../models/Leads/ContractTypes";
import {useClientsUsers} from "../../../providers/Clients/ClientsUsers/ClientsUsersProvider";
import {useGetFinancials} from "../../../hooks/fillSelects/useGetFinancials";
import {useGetProducts} from "../../../hooks/fillSelects/useGetProducts";
import {useGetProposalCategories} from "../../../hooks/fillSelects/useGetProposalCategories";
import {useGetChannels} from "../../../hooks/fillSelects/useGetChannels";
import _ from 'underscore';
import {toast} from "react-toastify";

interface ModalNotificationAutomationAddProps {
    show: boolean;
    onClose: any;
    notificationEdit?: NotificationAutomationCategoriesModel | any;
}

type FormValues = {
    type: string;
    title: string;
    description: string;
    proposalCategories: string;
    channels: string;
    quantity: number;
    messageSms: string;
    messagePush: string;
    messageEmail: string;
    imgNotification: string;
    urlCallback: string;
    contractType: string;
    incomeSources: MultiValue<any>;
    products: MultiValue<any>;
    financials: MultiValue<any>;
}

const defaultValues = {
    type: '',
    title: '',
    description: '',
    proposalCategories: '',
    quantity: 0,
    channels: '',
    messageSms: '',
    messagePush: '',
    messageEmail: '',
    imgNotification: '',
    urlCallback: '',
    contractType: '',
    incomeSources: [],
    products: [],
    financials: [],
} as FormValues;

const typeOptions = [
    {
        value: NotificationAutomationTypeEnum.PROPOSTA,
        label: NotificationAutomationTypeEnumDescription[NotificationAutomationTypeEnum.PROPOSTA],
    },
    {
        value: NotificationAutomationTypeEnum.SIMULACAO,
        label: NotificationAutomationTypeEnumDescription[NotificationAutomationTypeEnum.SIMULACAO],
    },
    {
        value: NotificationAutomationTypeEnum.LEADS,
        label: NotificationAutomationTypeEnumDescription[NotificationAutomationTypeEnum.LEADS],
    },
];

// enum of keys
const KeysEnum = {
    type: 'type',
    title: 'title',
    description: 'description',
    proposalCategories: 'proposalCategories',
    quantity: 'quantity',
    channels: 'channels',
    messageSms: 'messageSms',
    messagePush: 'messagePush',
    messageEmail: 'messageEmail',
    imgNotification: 'imgNotification',
    urlCallback: 'urlCallback',
    contractType: 'contractType',
    incomeSources: 'incomeSources',
    products: 'products',
    financials: 'financials',
}

const ModalNotificationAutomationAdd: FC<ModalNotificationAutomationAddProps> = ({show, onClose, notificationEdit}) => {
    const [selectedType, setSelectedType] = useState<any>();
    const [selectedChannel, setSelectedChannel] = useState<any>();
    const [selectedCategories, setSelectedCategories] = useState<any>();
    const [selectedFinancials, setselectedFinancials] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [channelsOptions, setChannelsOptions] = useState<any[]>([]);
    const [defaultTab, setDefaultTab] = useState<string>('');
    const [filesImg, setFilesImg] = useState<any[]>([]);
    const [messagePush, setMessagePush] = useState<string>('');
    const [messageSms, setMessageSms] = useState<string>('');
    const [messageEmail, setMessageEmail] = useState<string>('');
    const [urlImgEdit, setUrlImgEdit] = useState<string>('');
    const [selectedContractType, setSelectedContractType] = useState<any>('');
    const [selectedOccupationOption, setSelectedOccupationOption] = useState<any>([]);
    const {occupations} = useClientsUsers();
    const [occupationOptions, setOccupationOptions] = useState<any[]>([]);
    const {handleSave, isLoading, handleList} = useNotificationAutomation();
    const {onInvalid} = useAuth();
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        control,
        reset,
        formState: {errors}
    } = useForm<FormValues>({mode: 'onChange', defaultValues, resolver: yupResolver(schema)});
    const {financeiras} = useGetFinancials();
    const {products} = useGetProducts();
    const {proposalCategories} = useGetProposalCategories();
    const {channels} = useGetChannels();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            if (!!notificationEdit) {
                setSelectedType(() => typeOptions?.find((item: any) => notificationEdit.type?.id === item.value));
                setSelectedContractType(() => contractTypes?.find((item: any) => notificationEdit.contractType === item.value));
                setValue('title', notificationEdit.title);
                setValue('description', notificationEdit.description);
                setValue('quantity', notificationEdit.quantity);
                setValue('urlCallback', notificationEdit.urlCallback);
                setValue('contractType', notificationEdit.contractType);
                setValue('incomeSources', notificationEdit.incomeSources);
                setValue('products', notificationEdit.products);
                setValue('financials', notificationEdit.financials);
                notificationEdit.incomeSources?.forEach((item: any) => {
                    const _item = occupations?.find((x: any) => x.id === item);
                    _item && setSelectedOccupationOption((current: any) =>
                        [...current || [], {
                            value: _item.id,
                            label: _item.description
                        }]);
                });
                notificationEdit.financials?.forEach((item: any) => {
                    const _item = financeiras?.find((x: any) => x.value === item);
                    _item && setselectedFinancials((current: any) => [...current || [], _item]);
                });
                notificationEdit.products?.forEach((item: any) => {
                    const _item = products?.find((x: any) => x.value === item);
                    _item && setSelectedProducts((current: any) => [...current || [], _item]);
                });
                notificationEdit.channels?.forEach((item: any) => {
                    setSelectedChannel((current: any) => [...current || [], {
                        value: item.id,
                        label: item.name
                    }]);
                    switch (item.name?.toLowerCase()) {
                        case ChannelsEnum.SMS:
                            setMessageSms(item.message);
                            break;
                        case ChannelsEnum.PUSH:
                            setMessagePush(item.message);
                            setUrlImgEdit(item.imgUrl || '');
                            // !!item.imgFilename && setFilePush(item);
                            break;
                        case ChannelsEnum.EMAIL:
                            setMessageEmail(item.message);
                            break;
                        default:
                            break;
                    }
                    setDefaultTab(item.name?.toLowerCase());
                });
            }
        } else {
            reset();
            setSelectedType(null);
            setValue('type', '');

            setSelectedChannel(null);
            setValue('channels', '');

            setSelectedCategories([]);
            setMessagePush('');
            setMessageSms('');
            setMessageEmail('');
            setDefaultTab('');
            setFilesImg([]);
        }
    }, [show, notificationEdit]);

    useEffect(() => {
        if (notificationEdit?.proposalCategories?.length > 0) {
            const _categories = notificationEdit?.proposalCategories.map((item: any) => proposalCategories.find((x: any) => x.value === item))?.filter(Boolean);
            setSelectedCategories(() => _categories);
        }
    }, [notificationEdit, proposalCategories]);

    useEffect(() => {
        if (channels?.length > 0) {
            const _channels = channels.map((item: any) => ({label: item.name, value: item.id}))?.filter(Boolean);
            setChannelsOptions(_channels);
        }
    }, [channels]);

    useEffect(() => {
        setValue('type', selectedType);
    }, [selectedType]);

    useEffect(() => {
        setValue('channels', selectedChannel);
    }, [selectedChannel]);

    useEffect(() => {
        setValue('proposalCategories', selectedCategories);
    }, [selectedCategories]);

    useEffect(() => {
        setValue('messagePush', messagePush);
    }, [messagePush]);

    useEffect(() => {
        setValue('messageSms', messageSms);
    }, [messageSms]);

    useEffect(() => {
        setValue('messageEmail', messageEmail);
    }, [messageEmail]);

    useEffect(() => {
        if (occupations?.length > 0) {
            const _occupations = occupations.map((item: any) => ({
                label: item.description,
                value: item.id
            }))?.filter(Boolean)
            setOccupationOptions(() => _occupations);
        }
    }, [occupations]);

    useEffect(() => {
        setValue('products', selectedProducts);
    }, [selectedProducts]);
    useEffect(() => {
        setValue('financials', selectedFinancials);
    }, [selectedFinancials]);
    useEffect(() => {
        setValue('contractType', selectedContractType);
    }, [selectedContractType]);
    useEffect(() => {
        setValue('incomeSources', selectedOccupationOption || '');
    }, [selectedOccupationOption, occupations]);

    const onSubmit = async (data: any) => {
        const payload = await prepareData(data);
        const [, error] = await handleSave(payload).then();

        if (!!error) {
            toast.error(error);
            return;
        }

        toast.success('Cadastro realizado com sucesso!');
        handleList().then();
        onClose();
    }

    async function prepareData(data: any): Promise<any> {
        let _data = JSON.parse(JSON.stringify(data));
        const _channels = data.channels?.map((x: any) => {
            const _message: string = (x.label?.toLowerCase() === ChannelsEnum.PUSH) ? messagePush :
                (x.label?.toLowerCase() === ChannelsEnum.SMS) ? messageSms : messageEmail;
            return {id: x.value, message: _message,}
        });
        const _incomeSources = data.incomeSources?.map((x: any) => x.value) ?? [];
        !!notificationEdit && (_data.id = notificationEdit.id);
        filesImg?.length > 0 && await getBase64(filesImg[0]).then((file: any) => _data.imgNotification = file);
        _data.channels = _.uniq(_channels, 'id');
        _data.contractType = data.contractType?.value;
        _data.incomeSources = _.uniq(_incomeSources);
        _data.products = _.uniq(data.products?.map((x: any) => x.value)) ?? [];
        _data.financials = _.uniq(data.financials?.map((x: any) => x.value)) ?? [];
        _data.proposalCategories = _.uniq(data.proposalCategories?.map((x: any) => x.value)) ?? [];
        _data.type = selectedType?.value;

        delete _data[KeysEnum.messageSms];
        delete _data[KeysEnum.messagePush];
        delete _data[KeysEnum.messageEmail];

        return _data;
    }

    return (
        <ModalDefault
            className="ModalNotificationAutomationAdd"
            title={'Inserir Notificação'}
            show={show}
            sizeModal={'xxl'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            backdrop="static"
        >
            <div data-testid="ModalNotificationAutomationAdd">
                <div className="row">
                    <div className='col-12 col-md-6'>
                        <div className="row">
                            <div className='col-12 form-group'>
                                <label>Tipo *</label>
                                <Controller
                                    name='type'
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            options={typeOptions}
                                            placeholder='Selecione...'
                                            className={`form-control p-0 ${onInvalid(errors?.type)}`}
                                            value={selectedType || ''}
                                            defaultValue={selectedType}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            onChange={(val: any) => {
                                                onChange(!!val ? val : null);
                                                setSelectedType(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                                <ErrorMessage name='Tipo' type={errors?.type?.type}/>
                            </div>

                            <div className='col-12 form-group'>
                                <label className='form-control-label'>Título *</label>
                                <input
                                    type='text'
                                    className={`form-control ${onInvalid(errors?.title)}`}
                                    placeholder='Título'
                                    disabled={!selectedType}
                                    {...register('title')}
                                />
                                <ErrorMessage name="Título" type={errors?.title?.type} min={3} max={150}/>
                            </div>

                            <div className='col-12 form-group'>
                                <label className='form-control-label'>Descrição *</label>
                                <textarea
                                    className={`form-control ${onInvalid(errors?.description)}`}
                                    placeholder='Descrição'
                                    rows={3}
                                    disabled={!selectedType}
                                    {...register('description')}
                                />
                                <ErrorMessage name="Mensagem" type={errors?.description?.type} min={3} max={255}/>
                            </div>

                            <div className="col-8 form-group">
                                <label htmlFor="channels">Canais *</label>
                                <Controller
                                    name="channels"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isMulti
                                            isClearable
                                            isSearchable
                                            options={channelsOptions}
                                            placeholder="Selecione..."
                                            className={`form-control p-0 ${onInvalid(errors?.channels)}`}
                                            value={selectedChannel || ''}
                                            defaultValue={selectedChannel}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            onChange={(val: any) => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedChannel(val);

                                                const values = [...val];
                                                setDefaultTab(values.pop()?.label.toLowerCase());
                                            }}
                                            styles={customStyles}
                                            isDisabled={!selectedType}
                                        />
                                    )}
                                />
                                <ErrorMessage name="Canais" type={errors?.channels?.type}/>
                            </div>

                            <div className='col-4 form-group'>
                                <label>Quantidade de Disparos *</label>
                                <input
                                    type='number'
                                    className={`form-control ${onInvalid(errors?.quantity)}`}
                                    placeholder='Quantidade de disparos'
                                    disabled={!selectedType}
                                    {...register('quantity')}
                                />
                                <ErrorMessage name="Quantidade de Disparos" type={errors?.quantity?.type}
                                              min={1}/>
                            </div>

                            <div className='col-6 form-group'>
                                <label htmlFor='contractType' className='form-control-label'>Tipo Contratação</label>
                                <Controller
                                    name='contractType'
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={contractTypes}
                                            placeholder='Selecione...'
                                            className={`form-control p-0 ${onInvalid(errors?.contractType)}`}
                                            value={selectedContractType || ''}
                                            defaultValue={selectedContractType}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedContractType(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>

                            <div className='col-6 form-group'>
                                <label htmlFor='contractType' className='form-control-label'>Fonte de Renda</label>
                                <Controller
                                    name='incomeSources'
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            isMulti={true}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={occupationOptions}
                                            placeholder='Selecione...'
                                            className={`form-control p-0 ${onInvalid(errors?.incomeSources)}`}
                                            value={selectedOccupationOption || []}
                                            defaultValue={selectedOccupationOption}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedOccupationOption(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>

                            <div className='col-6 form-group'>
                                <label htmlFor='products' className='form-control-label'>Produtos</label>
                                <Controller
                                    name='products'
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isMulti
                                            isClearable
                                            isSearchable
                                            options={products}
                                            placeholder='Selecione...'
                                            className={`form-control p-0 ${onInvalid(errors?.products)}`}
                                            value={selectedProducts || ''}
                                            defaultValue={selectedProducts}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedProducts(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>

                            <div className='col-6 form-group'>
                                <label htmlFor='financials' className='form-control-label'>Financeiras</label>
                                <Controller
                                    name='financials'
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isMulti
                                            isClearable
                                            isSearchable
                                            options={financeiras}
                                            placeholder='Selecione...'
                                            className={`form-control p-0 ${onInvalid(errors?.financials)}`}
                                            value={selectedFinancials || ''}
                                            defaultValue={selectedFinancials}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setselectedFinancials(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>

                            {selectedChannel?.some((x: any) => [ChannelsEnum.SMS, ChannelsEnum.PUSH].includes(x.label?.toLowerCase())) &&
                                <div className='col-12 form-group'>
                                    <label className='form-control-label'>URL</label>
                                    <input
                                        type='text'
                                        className={`form-control ${onInvalid(errors?.urlCallback)}`}
                                        placeholder='URL'
                                        {...register('urlCallback')}
                                    />
                                    <ErrorMessage
                                        message={errors?.urlCallback?.message}
                                        name='Categorias de Proposta'
                                    />
                                    <ErrorMessage name="URL" type={errors?.urlCallback?.type} min={10} max={115}/>
                                </div>}

                            {selectedType?.value === NotificationAutomationTypeEnum.PROPOSTA &&
                                <div className="col-12 form-group">
                                    <label htmlFor='proposalsCategories'>Categorias de Proposta *</label>
                                    <Controller
                                        name='proposalCategories'
                                        control={control}
                                        render={({field: {onChange, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isMulti
                                                isClearable
                                                isSearchable
                                                options={proposalCategories}
                                                placeholder='Selecione...'
                                                className={`form-control p-0 ${onInvalid(errors?.proposalCategories)}`}
                                                value={selectedCategories || ''}
                                                defaultValue={selectedCategories}
                                                components={animatedComponents}
                                                onChange={val => {
                                                    onChange(val?.length > 0 ? val : null);
                                                    setSelectedCategories(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        message={errors?.proposalCategories?.message}
                                        name='Categorias de Proposta'
                                    />
                                </div>}

                        </div>
                    </div>

                    <div className='col-12 col-md-6 position-relative'>
                        <Tabs
                            defaultActiveKey={defaultTab}
                            activeKey={defaultTab}
                            onSelect={(k) => setDefaultTab(k || '')}
                            className={`m-0 ${selectedChannel?.length === 0 ? 'd-none' : ''}`}
                        >
                            <Tab
                                eventKey={ChannelsEnum.PUSH}
                                title={
                                    <>
                                        <span>Push</span>

                                        {onInvalid(errors?.messagePush) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col">
                                            <div className="col-12 text-start mb-3">
                                                <InputDropzone
                                                    label="Imagem"
                                                    name="imgCampaign"
                                                    files={filesImg}
                                                    setFiles={setFilesImg}
                                                    types={
                                                        {
                                                            'image/png': ['.png', '.jpg'],
                                                        }
                                                    }
                                                    maxSize={307200}
                                                />
                                                <p className="message-warning">Tamanho ideal 1920x640px</p>
                                            </div>

                                            <TagifyDefault
                                                inputName="messagePush"
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                                text={messagePush}
                                                setText={setMessagePush}
                                                min={3}
                                                max={220}
                                                title="Mensagem"
                                                placeholder="Corpo da Mensagem Push"
                                            />

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <div className="obs-warning">
                                                        <p>Para selecionar uma tag, digitar <b>@</b> ou <b>#</b><br/>e
                                                            em seguida o nome.</p>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="badge badge-sm mx-1">primeiro_nome</div>
                                                                <div className="badge badge-sm mx-1">valor_liberado
                                                                </div>
                                                                <div className="badge badge-sm mx-1">url_formalizacao
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {!!urlImgEdit &&
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <span className='text-sm'>Imagem Atual</span>
                                                        <img src={urlImgEdit} className='img-push'
                                                             alt='imagem do push'/>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="col">
                                            <PreviewCampaigns
                                                title={getValues('title')}
                                                describe={messagePush || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.PUSH}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey={ChannelsEnum.SMS}
                                title={
                                    <>
                                        <span>SMS</span>

                                        {onInvalid(errors?.messageSms) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col">
                                            <TagifyDefault
                                                inputName="messageSms"
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                                text={messageSms}
                                                setText={setMessageSms}
                                                min={3}
                                                max={115}
                                                title="Mensagem"
                                                placeholder="Corpo da Mensagem SMS"
                                            />

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <div className="obs-warning">
                                                        <p>Para selecionar uma tag, digitar <b>@</b> ou <b>#</b><br/>e
                                                            em seguida o nome.</p>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="badge badge-sm mx-1">primeiro_nome</div>
                                                                <div className="badge badge-sm mx-1">valor_liberado
                                                                </div>
                                                                <div className="badge badge-sm mx-1">url_formalizacao
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <PreviewCampaigns
                                                title={getValues('title')}
                                                describe={messageSms || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.SMS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey={ChannelsEnum.EMAIL}
                                title={
                                    <>
                                        <span>Email</span>

                                        {onInvalid(errors?.messageEmail) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col-7">
                                            <label className='form-control-label w-100'>Corpo do Email *</label>
                                            <HtmlEditor
                                                setHtml={setMessageEmail}
                                                html={messageEmail}
                                                fieldName='Descrição'
                                                fieldError={errors?.messageEmail}
                                                minCharacter={10}
                                                maxCharacter={60000}
                                            />
                                        </div>
                                        <div className="col-5">
                                            <PreviewCampaigns
                                                title={getValues('title')}
                                                describe={messageEmail || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.EMAIL}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                        {selectedChannel?.length === 0 && (
                            <div className="zone">
                                <p className="m-0 message-preview">Selecione os canais ao lado para pré-visualizar a
                                    notificação.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalNotificationAutomationAdd;
