import React, {FC, ReactNode, useEffect, useState} from 'react';
import './NotificationAutomation.scss';
import PageBase from "../../components/PageBase/PageBase";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import {useNotificationAutomation} from "../../providers/NotificationAutomation/NotificationAutomationProvider";
import SkeletonTable from "../../components/SkeletonTable/SkeletonTable";
import {NotificationAutomationModel} from "../../models/NotificationAutomation/NotificationAutomationModel";
import FormataData from "../../utils/FormataData";
import ButtonEdit from "../../components/ButtonEdit/ButtonEdit";
import NotificationAutomationFilter
    from "../../components/NotificationAutomation/NotificationAutomationFilter/NotificationAutomationFilter";
import {usePaginate} from "../../providers/PaginateProvider";
import ModalNotificationAutomationAdd
    from "../../components/NotificationAutomation/ModalNotificationAutomationAdd/ModalNotificationAutomationAdd";
import TooltipItem from "../../components/TooltipItem/TooltipItem";

interface NotificationAutomationProps {
}

const NotificationAutomation: FC<NotificationAutomationProps> = () => {
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [notificationEdit, setNotificationEdit] = useState<any>();

    const {notificationsAutomation, handleList, isLoading} = useNotificationAutomation();
    const {setShowFilters} = usePaginate();

    useEffect(() => {
        handleList().then();
    }, []);

    const renderType = (item: NotificationAutomationModel): ReactNode => {
        if (!item.type?.id) {
            return <span className='badge bg-danger'>NÃO ENCONTRADO</span>;
        }

        return <span key={item.type.id} className='badge bg-success'>{item.type.description} </span>;
    }
    const renderList = () => {
        if (notificationsAutomation?.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return notificationsAutomation?.map((item: NotificationAutomationModel) => (
            <tr key={item.id}>
                <td className='text-sm'>
                    {renderType(item)}
                </td>
                <td className='text-sm text-wrap'>{item.title}</td>
                <td className='text-sm text-wrap'>{item.description}</td>
                <td className='text-sm'>{item.totalSend}</td>
                <td className='text-sm text-wrap'>{item.channels?.map((channel: any) => (
                    <span className='badge badge-sm bg-gradient-dark me-2 my-1'
                          key={channel?.name}>{channel?.name}</span>
                ))}</td>
                <td className='text-sm'>{FormataData(item.updatedAt)}</td>
                <td className='text-sm'>
                    <span className='d-flex justify-content-center gap-2'>
                        <ButtonEdit
                            tooltipText='Editar'
                            onClick={() => {
                                setShowAdd(true);
                                setNotificationEdit(item);
                            }}
                        />
                        {item.type?.id !== 3 ?
                            <TooltipItem position={'top'} title='Visualizar'>
                                <div role="button" onClick={() => {

                                }}>
                                    <i className="fas fa-check"
                                       aria-hidden="true"></i>
                                </div>
                            </TooltipItem>
                            :
                            <TooltipItem position={'top'} title='Visualizar'>
                                <div role="button" onClick={() => {

                                }}>
                                    <i className="fas fa-ban"
                                       aria-hidden="true"></i>
                                </div>
                            </TooltipItem>
                        }
                    </span>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return <>
            {!isLoading ? (
                <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="col-2" scope="col">TIPO</th>
                            <th scope="col">TÍTULO</th>
                            <th scope="col">DESCRIÇÃO</th>
                            <th scope="col">QUANTIDADE ENVIO</th>
                            <th scope="col">CANAIS</th>
                            <th className="col-2" scope="col">DATA</th>
                            <th className='col-1 text-center'>#</th>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {renderList()}
                        </tbody>
                    </table>
                </div>
            ) : (
                <SkeletonTable/>
            )}
        </>
    }

    return (
        <PainelMasterPage
            title={'Leads'}
            icon={'fas fa-comments-dollar'}
            className="NotificationAutomation"
        >
            <div data-testid="NotificationAutomation">
                <PageBase
                    content={renderContent()}
                    title={'Listagem de Automação de Notificações'}
                    subtitle={`Aqui é listado as automações de notificações`}
                    textButtonAdd='+ Adicionar'
                    handleFilter={() => setShowFilters(true)}
                    hasFilter={true}
                    handleAdd={() => {
                        setShowAdd(true);
                        setNotificationEdit(null);
                    }}
                />

                <ModalNotificationAutomationAdd
                    show={showAdd}
                    onClose={setShowAdd}
                    notificationEdit={notificationEdit}
                />

                <NotificationAutomationFilter/>
            </div>
        </PainelMasterPage>
    )
};

export default NotificationAutomation;
