import FileSaver from 'file-saver';
import apiDashboard from "./ApiDashboard";

const URL = 'api/contracts/formalization/pdf';

class FormalizacaoService {
    async getPdf(guid: string) {
        try {
            const response = await apiDashboard.post(`${URL}`, {
                guid
            }, {responseType: 'blob'});

            FileSaver.saveAs(response.data, `${guid}.pdf`);

            return true;
        } catch (e: any) {
            return false;
        }
    }
}

export default FormalizacaoService;