const mapValues = async (params: any, key: string): Promise<void> => {
    if (params[key] && params[key].every((x: any) => typeof x === 'object')) {
        params[key] = params[key].map((x: any) => x.value);
    }
};

const prepareParams = async (params: any, seeAll: boolean, userLogadoId: number): Promise<any> => {
    const keysToMap = ['_financeira', '_produto', '_tabela', 'origem', 'digitador'];

    for (const key of keysToMap) {
        await mapValues(params, key);
    }

    if (!seeAll) {
        params.digitador = [userLogadoId];
    }

    return params;
};

export const prepareQueryProposalParams = async (params: any, seeAll: boolean, userLogadoId: number): Promise<any> => await prepareParams(params, seeAll, userLogadoId);