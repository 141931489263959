import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {ProposalsTrackModel} from "../../models/ProposalsTrack/ProposalsTrackModel";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {useLocation} from "react-router-dom";
import {ProposalsTrackStatusEnum} from "../../models/ProposalsTrack/ProposalsTrackStatusEnum";
import {ProposalsTrackService} from "../../services/ProposalsTrackService";
import {ProposalsTrackErrorParams} from "../../models/ProposalsTrack/ProposalsTrackErrorParams";
import {useAuth} from "../AuthProvider";
import {ProposalsTrackParam} from "../../models/ProposalsTrack/ProposalsTrackParams";
import {ProposalModel} from "../../models/ProposalsTrack/ProposalModel";
import {toast} from "react-toastify";
import {ToastSettings} from "../../components/ToastSettings";
import {SummaryProposalsModel} from "../../models/ProposalsTrack/SummaryProposalsModel";
import moment from "moment";

interface ContextProps {
    proposals: ProposalsTrackModel[],
    proposalsError: ProposalsTrackModel[],
    proposalSended: ProposalsTrackModel | undefined,
    summaryProposals: SummaryProposalsModel | null
    proposalsErrorParam: ProposalsTrackErrorParams,
    proposalsParam: ProposalsTrackParam,
    setProposalsErrorParam: Dispatch<SetStateAction<ProposalsTrackErrorParams>>,
    setProposalsParam: Dispatch<SetStateAction<ProposalsTrackParam>>,
    isLoading: boolean,
    isLoadingFile: boolean,
    isLoadingSummary: boolean,
    error: string,
    pagesError: PagesPaginateModel,
    pages: PagesPaginateModel,
    handleListError: () => Promise<any>,
    handleList: () => Promise<any>,
    handleSend: (proposalId: number) => Promise<boolean>,
    handleUpdate: (proposalId: number, status: ProposalsTrackStatusEnum, proposalExternalId: number | null) => Promise<boolean>,
    handlePaginateError: (pageNumber: number) => void,
    handlePaginate: (pageNumber: number) => void,
    handleExcel: () => Promise<any>,
    handleFinishProposal: (proposalId: number, type: 'paid' | 'cancel') => Promise<any>,
}

export const ProposalsTrackContext = createContext<ContextProps>({} as ContextProps);

interface ProposalsTrackProviderProps {
    children: ReactNode
}

export const ProposalsTrackProvider = ({children}: ProposalsTrackProviderProps) => {
    const [proposals, setProposals] = useState<ProposalModel[]>([]);
    const [proposalsError, setProposalsError] = useState<ProposalsTrackModel[]>([]);
    const [proposalSended, setProposalSended] = useState<ProposalsTrackModel>();
    const [summaryProposals, setSummaryProposals] = useState<SummaryProposalsModel | null>(null);
    const [proposalsErrorParam, setProposalsErrorParam] = useState<ProposalsTrackErrorParams>({} as ProposalsTrackErrorParams);
    const [proposalsParam, setProposalsParam] = useState<ProposalsTrackParam>({} as ProposalsTrackParam);
    const [error, setError] = useState<string>('');
    const [pagesError, setPagesError] = useState<PagesPaginateModel>({} as PagesPaginateModel);
    const [pages, setPages] = useState<PagesPaginateModel>({} as PagesPaginateModel);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(false);
    const [totalProposals, setTotalProposals] = useState<number>(0);

    const {userLogado, CanAccess} = useAuth();
    const service = new ProposalsTrackService();
    const location = useLocation();

    const handleListError = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/list-error')) {
            return false;
        }
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(proposalsErrorParam);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }
            setProposalsError(_Response.data);
            setPagesError(_Response.pages);
            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, proposalsErrorParam]);

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/list')) {
            return false;
        }
        try {
            setIsLoading(true);
            CanAccess('leads.ver_tudo.regra').then(async (seeAll: boolean) => {
                if (!proposalsParam?.startDate && !proposalsParam?.skipFilterDate) {
                    proposalsParam.startDate = moment(new Date()).subtract(10, 'days').format('YYYY-MM-DD');
                    proposalsParam.endDate = moment(new Date()).format('YYYY-MM-DD');
                }
                delete proposalsParam?.skipFilterDate;
                const [_response, _error] = await service.listAll(proposalsParam, seeAll, userLogado?.id);
                if (!!_error) {
                    setIsLoading(false);
                    return setError(_response || _error);
                }
                setProposals(_response.data);
                getSummary(proposalsParam, seeAll, userLogado?.id, _response.pages.total ?? 0).then();

                setTotalProposals(_response.pages?.total ?? 0);
                setPages(_response.pages);

                return setIsLoading(false);
            });
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, proposalsParam]);

    const getSummary = async (params: ProposalsTrackParam, seeAll: boolean, userLogadoId?: number, total?: number): Promise<any> => {
        if (total === totalProposals) {
            return '';
        }
        setIsLoadingSummary(true);
        try {
            const [_response, _error] = await service.listProposalSummary(params, seeAll, userLogadoId);
            if (!!_error) {
                return setError(_error || _response);
            }
            setSummaryProposals(_response.data);
            setIsLoadingSummary(false);
            return '';
        } catch (e) {
            console.warn(e);
            setIsLoadingSummary(false);
            return '';
        }
    };

    const handleExcel = async () => {
        try {
            setError('');
            setIsLoadingFile(true);
            CanAccess('leads.ver_tudo.regra').then(async (x: boolean) => {
                const [_Response, _Error] = await service.export(proposalsParam, x, userLogado?.id);

                if (_Error) {
                    setIsLoadingFile(false);
                    ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                    return false;
                }

                toast.success(_Response?.message || 'Relatório gerado com sucesso!');
                return setIsLoadingFile(false);
            });
        } catch (e) {
            console.warn(e);
            return setIsLoadingFile(false);
        }
    };

    const handleSend = async (proposalId: number): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.send(proposalId);
            if (!!_Error) {
                setIsLoading(false);
                setProposalSended(undefined);
                setError(_Response || _Error);
                return false;
            }
            setProposalSended(_Response.data);
            setError('');
            setIsLoading(false);
            return true;
        } catch (e) {
            setProposalSended(undefined);
            setIsLoading(false);
            console.warn(e);
            return false;
        }
    }

    const handleUpdate = async (proposalId: number, status: ProposalsTrackStatusEnum, proposalExternalId: number | null = null): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.update(proposalId, Number(status), proposalExternalId);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Response?.financeiraStatusMessage || _Error);
                return false;
            }
            setError('');
            setIsLoading(false);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.warn(e);
            return false;
        }
    }

    const handleFinishProposal = async (proposalId: number, type: 'paid' | 'cancel'): Promise<any> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.finishProposal(proposalId, type);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error || _Response?.message);
                return _Error;
            }
            setError('');
            setIsLoading(false);
            return '';
        } catch (e) {
            setIsLoading(false);
            return e;
        }
    }

    const handlePaginateError = (pageNumber: number) => setProposalsErrorParam({
        ...proposalsErrorParam,
        page: pageNumber
    });

    const handlePaginate = (pageNumber: number) => setProposalsParam({
        ...proposalsParam,
        page: pageNumber
    });

    useEffect(() => {
        handleListError().then();
    }, [handleListError]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProposalsTrackContext.Provider value={{
            proposals,
            proposalsError,
            proposalSended,
            summaryProposals,
            proposalsErrorParam,
            proposalsParam,
            setProposalsErrorParam,
            setProposalsParam,
            error,
            handleListError,
            handleList,
            handleSend,
            handleUpdate,
            handlePaginateError,
            handlePaginate,
            handleExcel,
            pagesError,
            pages,
            isLoading,
            isLoadingFile,
            isLoadingSummary: isLoadingSummary,
            handleFinishProposal,
        }}>
            {children}
        </ProposalsTrackContext.Provider>
    )
}

export const useProposalsTrack = () => useContext(ProposalsTrackContext);
